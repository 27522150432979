(function($, window, document) {
  'use strict';

  /** @type {Object} Default options */
  var defaults = {
    debug: false,
    name: 'App',
  };

  /** @type {Object} Store with useful data */
  var store = {
    view: new Viewport(),
  };

  /** @type {Object} Test for stuff */
  var is = {
    get mobile() {
      return store.view.width < 768;
    },
    get tablet() {
      return store.view.width >= 768 && store.view.width <= 1024;
    },
    get desktop() {
      return store.view.width > 1024;
    },
    get mac() {
      return (
        navigator.platform === 'MacIntel' || navigator.platform === 'MacPPC'
      );
    },
    get ie() {
      return getBrowser() === 'ie';
    },
  };

  /**
   * App constructor
   *
   * @return {Object} The app's instance
   */
  function App(options) {
    if (!(this instanceof App)) return new App(options);
    var self = this;

    // Mix instance and defaults options
    self.options = $.extend(true, {}, defaults, options);

    // Set properties
    self.store = store;
    self.is = is;

    // Debug helper
    self.store.hasDebug = self.options.debug;

    // Generic DOM elements
    self.$document = $(document);
    self.$html = $('html');

    // Update classes on resizend event
    self.store.view.onResizeend.setIsClasses = self.setIsClasses.bind(self);

    self.setIsClasses();
    self.$html.removeClass('no-js');

    // Links anchors animations
    var $links = $('a[href*="#"].js-anchor-scroll');
    $links.on('click', function() {
      var selector = this.href.split('#')[1];
      var $target = document.querySelector('#' + selector);
      var scrollTo = $target
        ? $target.getBoundingClientRect().top +
          document.documentElement.scrollTop
        : 0;

      if (!$target) {
        return false;
      }

      // TweenLite.to(self.$html, 0.75, {
      //  scrollTop: scrollTo,
      // });
      self.$html.scrollTop = scrollTo;

      return false;
    });

    // Static assets
    self.staticPath = '/wp-content/themes/cornelius/asset';

    // Lazy load
    self.bLazy = new Blazy({
      selector: '.js-lazy',
      offset: 300,
      successClass: 'is-lazy-loaded',
      errorClass: 'is-lazy-error',
    });

    // tarteaucitron.init({
    //   privacyUrl: '', /* Privacy policy url */

    //   hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
    //   cookieName: 'tarteaucitron', /* Cookie name */

    //   orientation: 'bottom', /* Banner position (top - bottom) */
    //   showAlertSmall: false, /* Show the small banner on bottom right */
    //   cookieslist: true, /* Show the cookie list */

    //   adblocker: false, /* Show a Warning if an adblocker is detected */
    //   AcceptAllCta : true, /* Show the accept all button when highPrivacy on */
    //   highPrivacy: true, /* Disable auto consent */
    //   handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */

    //   removeCredit: false, /* Remove credit link */
    //   moreInfoLink: true, /* Show more info link */
    //   useExternalCss: true, /* If false, the tarteaucitron.css file will be loaded */

    //   //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

    //   readmoreLink: '/cookiespolicy' /* Change the default readmore link */
    // });

    // // Google Tag Manager
    // tarteaucitron.user.googletagmanagerId = 'GTM-TJZ5BCC';
    // (tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');

    return self;
  }

  // Set App prototypes
  App.prototype = $.extend(true, App.prototype, {
    setIsClasses: setIsClasses,
    getBrowser: getBrowser,
  });

  /**
   * Set `is` classes on html
   */
  function setIsClasses(view) {
    /* eslint-disable */
    for (var key in this.is) {
      var method = this.is[key] ? 'addClass' : 'removeClass';
      this.$html[method]('is-' + key);
    }

    this.$html.addClass('is-' + this.getBrowser());
  }

  function getBrowser() {
    /* eslint-disable */
    return navigator.userAgent.indexOf('Chrome') > -1
      ? 'chrome'
      : navigator.userAgent.indexOf('Safari') > -1
      ? 'safari'
      : navigator.userAgent.indexOf('Opera') > -1
      ? 'opera'
      : navigator.userAgent.indexOf('Firefox') > -1
      ? 'firefox'
      : navigator.userAgent.indexOf('MSIE') > -1 ||
        navigator.userAgent.indexOf('Trident') > -1
      ? 'ie'
      : 'unknow';
  }

  /* ================================
   * Init app globally
   * ================================ */

  window.APP = new App({
    debug: 'HAS_DEBUG' in window && window.HAS_DEBUG,
    name: 'Cornelius',
  });
})(jQuery, window, document);
